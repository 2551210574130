const quotesData = [
    {
        id: 1,
        quote: "I'm so black",
        author: "Lars"
    },
    {
        id: 2,
        quote: "I don't want to be brave, I just want to be drunk",
        author: "Vidar"
    },
    {
        id: 3,
        quote: "We don't walk enough on the grass with our bare feet",
        author: "Jørgen"
    },
    {
        id: 4,
        quote: "The sun never goes down behind the garage",
        author: "Jørgen"
    },
    {
        id: 5,
        quote: "Scampi Pil Pil",
        author: "Matthew"
    },
    {
        id: 6,
        quote: "I get red",
        author: "Hannah"
    },
    {
        id: 7,
        quote: "You are the flower in my eyes Marianne",
        author: "Jørgen"
    },
    {
        id: 8,
        quote: "There is something wrong with your body Siri",
        author: "Astrid"
    },
    {
        id: 9,
        quote: "I looked like the gay one, now we look like the gay ones",
        author: "Matthew"
    },
    {
        id: 10,
        quote: "There is more Siri than the naked eye sees",
        author: "Magnus"
    },
    {
        id: 11,
        quote: "Post traumatic Siri disorder",
        author: "Matthew"
    },
    {
        id: 12,
        quote: "Fuck you Tim",
        author: "Marianne"
    },
    {
        id: 13,
        quote: "Jørgen, you are the weirdest person I know",
        author: "Siri"
    },
    {
        id: 14,
        quote: "I never have the last word",
        author: "Siri"
    },
    {
        id: 15,
        quote: "Not everything can be a quote",
        author: "Jørgen"
    },
    {
        id: 16,
        quote: "19 guys and 7 minutes each",
        author: "Siri"
    },
    {
        id: 17,
        quote: "Wake up Matt",
        author: "Thomas"
    },
    {
        id: 18,
        quote: "If you hit it at 200mph then you are dead. Both you and the seagull",
        author: "Vidar"
    },
    {
        id: 19,
        quote: "Fucking hell you can drink, but you can't drink",
        author: "Vidar"
    },
    {
        id: 20,
        quote: "I don't respect people who like oysters",
        author: "Thomas"
    },
    {
        id: 21,
        quote: "This is the only European trip you'll be on all year",
        author: "Matthew"
    },
    {
        id: 22,
        quote: "My name is Siri but my friends call me Michael Kevin",
        author: "Siri"
    },
    {
        id: 23,
        quote: "You're with Jørgen, right?",
        author: "David"
    },
    {
        id: 24,
        quote: "That is a nice Juan",
        author: "Nils"
    },
    {
        id: 25,
        quote: "If I ever see Haaland I will slide tackle him",
        author: "Matthew"
    },
    {
        id: 26,
        quote: "That is something we should do",
        author: "Rolf"
    },
    {
        id: 27,
        quote: "Dumba dumba dumba",
        author: "Matthew"
    },
    {
        id: 28,
        quote: "I need to get Yasir's number tonight",
        author: "Siri"
    },
    {
        id: 29,
        quote: "You don't need to go down at all",
        author: "Marianne"
    },
    {
        id: 30,
        quote: "I'm not coming to Andy's tomorrow, I'm getting too friendzoned",
        author: "Magnus"
    },
    {
        id: 31,
        quote: "It's one of my favourites, how does it go again",
        author: "Matthew"
    },
    {
        id: 32,
        quote: "I'm so tucked",
        author: "Juan"
    },
    {
        id: 33,
        quote: "Magnus is fewmin again",
        author: "Siri"
    },
    {
        id: 34,
        quote: "Could be worse could be bad",
        author: "Stinky Pete"
    },
    {
        id: 35,
        quote: "I would love to eat Bobby's meat",
        author: "Siri"
    },
    {
        id: 36,
        quote: "I need to have a dick",
        author: "Siri"
    },
    {
        id: 37,
        quote: "I'm not that tall but my feet stick out the bed",
        author: "Siri"
    },
    {
        id: 38,
        quote: "I don't really think about dicks",
        author: "Vidar"
    },
    {
        id: 39,
        quote: "I'm telling you Goran, you have to bring your DVD player",
        author: "Rolf"
    },
    {
        id: 40,
        quote: "Have you eaten all of it? I'll bring you an aquavit!",
        author: "Curry"
    },
    {
        id: 41,
        quote: "You're going to hate me for this Marianne but fuck off",
        author: "Vidar"
    },
    {
        id: 42,
        quote: "You know what it was yesterday? It was the world championship of cock",
        author: "PK"
    },
    {
        id: 43,
        quote: "I see the appeal of being a criminal",
        author: "Henrik"
    },
    {
        id: 44,
        quote: "I cracked my nuts by myself",
        author: "Carlos"
    },
    {
        id: 45,
        quote: "We got so fucking drunk",
        author: "Torstein"
    },
    {
        id: 46,
        quote: "I saw this clip of a bear on cocaine - he took it all and killed everybody",
        author: "Torstein"
    },
    {
        id: 47,
        quote: "I'm not even drunk, I'm just excited",
        author: "Ben"
    },
    {
        id: 48,
        quote: "You Panglossian rebarbative",
        author: "Siri"
    },
    {
        id: 49,
        quote: "What happened to my score",
        author: "Matthew"
    },
    {
        id: 50,
        quote: "I approved Siri's request to join our household",
        author: "Kamilla"
    },
    {
        id: 51,
        quote: "I hope they do some shit with that bingo",
        author: "Vidar"
    },
    {
        id: 52,
        quote: "Most people who get a tattoo get a dick",
        author: "Matthew"
    },
    {
        id: 53,
        quote: "I'm following this guy on TikTok - oh no wait I don't have TikTok",
        author: "Jørgen"
    },
    {
        id: 54,
        quote: "Let's go get knackered",
        author: "Jørgen"
    }
];

export default quotesData;