import './App.scss';
import Quote from './Quote.js'

function App() {
  return (
    <>
      <Quote />
    </>
  );
}

export default App;
